import React, { useContext } from "react";
import { HostAuthContext } from "../../context/hostAuth";
import { GuestAuthContext } from "../../context/guestAuth";
import { guestClient, hostClient } from "../../GraphqlApolloClients";

export default function Logout({ initialUserType }) {
    const hostContext = useContext(HostAuthContext);
    const guestContext = useContext(GuestAuthContext);

    guestContext.logoutGuest();
    hostContext.logoutHost();

    guestClient.cache.reset();
    hostClient.cache.reset();

    // if (hostContext.host) {
    //     console.log("host");
    // } else if (guestContext.guest) {
    //     console.log("guest");
    // }

    return (
        <div className="h-full flex-1 flex mx-8 sm:mx-24 md:mx-32 lg:mx-48 mb-8">
            Logging out...
        </div>
    );
}