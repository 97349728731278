
   
import React from "react";
import { PiNumberCircleOneBold , PiNumberCircleTwoBold, PiNumberCircleThreeBold} from 'react-icons/pi';

export default function Steps() {
  return (
    <div className="flex md:flex-row flex-col gap-8 items-center w-full justify-start mt-32">
      <div className="px-6 pb-6 pt-16 flex-col text-white flex w-full md:w-1/3 h-64 rounded-lg border-2 bg-amber-600 border-amber-600 text-left">
        <PiNumberCircleOneBold size={24} color={"white"}/>
        <h3 className="font-bold text-xl leading-none pt-4 pb-2">Create an account</h3>
        <p className="text-xs leading-tight">Let us know what sort of place you're looking for, how long you want to stay there, and more details. </p>
      </div>
      <div className="px-6 pb-6 pt-16 flex-col text-white flex w-full md:w-1/3 h-64 rounded-lg border-2 bg-amber-700 border-amber-700 text-left">
        <PiNumberCircleTwoBold size={24} color={"white"}/>
        <h3 className="font-bold text-xl leading-none pt-4 pb-2">Browse rentals</h3>
        <p className="text-xs leading-tight">Apply to rent one of many verified places, and we'll share your preferences with the owner.</p>
      </div>
      <div className="px-6 pb-6 pt-16 flex-col text-white flex w-full md:w-1/3 h-64 rounded-lg border-2 bg-amber-800 border-amber-800 text-left">
      <PiNumberCircleThreeBold size={24} color={"white"}/>
        <h3 className="font-bold text-xl leading-none pt-4 pb-2">Secure a place</h3>
        <p className="text-xs leading-tight">We'll schedule 15-min chats for you to ask any further questions and meet your future roommates and landlords before you book.</p>
      </div>
    </div>
  );
}
