import React from "react";
// import { HostAuthContext } from "../../context/hostAuth";

export default function HostProfile() {
  // const hostContext = useContext(HostAuthContext);
  // console.log(hostContext);
  return (
    <div className="h-full flex flex-col min-h-screen w-full">
      <div className="h-full flex-1 flex mx-8 sm:mx-24 md:mx-32 lg:mx-48 mb-8">
        <div className="flex items-start justify-start flex-col w-full">
          HostProfile
        </div>
      </div>
    </div>
  );
}