import React from "react";
import Header from "../../components/common/Header";
import SignupContent from "../../components/common/SignupContent";
import NavBar from "../../components/common/NavBar";
import Footer from "../../components/common/Footer";

export default function Signup({ initialUserType }) {
    return (
        <div className="h-full flex flex-col min-h-screen w-full">
            <NavBar />
            <div className="h-full flex-1 flex">
                <div className="flex items-start justify-center flex-col w-full">
                    <div className="px-16 md:px-32 w-full py-16 h-full">
                        <Header
                            description={
                                `Want to find a place to rent, hassle-free? Sign up below.`
                            }
                            title={"Sign Up"}
                        />
                        <SignupContent initialUserType={initialUserType} />
                    </div>
                    <Footer />
                </div>
            </div>
        </div>
    );
}