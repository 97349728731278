import React, { useContext } from "react";
import { GuestAuthContext } from "../../context/guestAuth";
import { guestClient } from "../../GraphqlApolloClients";
import ProfileContent from "../../components/guest/ProfileContent";
import { gql, useQuery } from "@apollo/client";
import NavBar from "../../components/common/NavBar";
import Footer from "../../components/common/Footer";

export default function GuestProfile() {
  const guestContext = useContext(GuestAuthContext);

  const { data: { getGuestById: guest } = {} } = useQuery(
    GET_GUEST_BY_ID,
    {
      client: guestClient,
      variables: { guestId: guestContext.guest?.id },
      retry: 10,
    }
  );

  if (!guestContext.guest) {
    window.location.href = `${window.location.origin}/login`;
  }

  return guest ? (
    <div className="h-full flex flex-col min-h-screen w-full">
      <NavBar />
      <div className="h-full flex-1 flex">
        <div className="flex items-start justify-center flex-col w-full">
          <div className="px-16 md:px-32 w-full pb-16">
            <h1 className="text-4xl pt-16">Guest Profile</h1>
            <h3 className="leading-tight text-md pt-2">Create your profile below, for us to understand what type of place you're looking for.</h3>
            <ProfileContent guest={guest} />
          </div>
          <Footer />
        </div>
      </div>
    </div>
  ) : <></>;
}

export const GET_GUEST_BY_ID = gql`
query getGuestById($guestId: String!) {
  getGuestById(guestId: $guestId) {
    id
    name
    password
    email
    createdAt
    bedrooms
    bathrooms
    startDate
    duration
    gender
    rentMin
    rentMax
    university
    roommates
  }
}
`;