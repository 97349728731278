import React, { createContext, useReducer } from "react";
import jwtDecode from "jwt-decode";

const initialState = {
  user: null,
};

if (localStorage.getItem("jwtToken")) {
  const decodedToken = jwtDecode(localStorage.getItem("jwtToken"));

  if (decodedToken.exp * 1000 < Date.now()) {
    localStorage.removeItem("jwtToken");
  } else {
    initialState.host = decodedToken;
  }
}

const HostAuthContext = createContext({
  host: null,
  loginHost: (hostData) => { },
  logoutHost: () => { },
});

function hostAuthReducer(state, action) {
  switch (action.type) {
    case "LOGIN_HOST":
      return {
        ...state,
        host: action.payload,
      };
    case "LOGOUT_HOST":
      return {
        ...state,
        host: null,
      };
    default:
      return state;
  }
}

function HostAuthProvider(props) {
  const [state, dispatch] = useReducer(hostAuthReducer, initialState);

  function loginHost(hostData) {
    localStorage.setItem("jwtToken", hostData.token);
    dispatch({
      type: "LOGIN_HOST",
      payload: hostData,
    });
  }

  function logoutHost() {
    localStorage.removeItem("jwtToken");
    dispatch({ type: "LOGOUT_HOST" });
  }

  return (
    <HostAuthContext.Provider
      value={{ host: state.host, loginHost, logoutHost }}
      {...props}
    />
  );
}

export { HostAuthContext, HostAuthProvider };