import React from "react";
// import { useNavigate } from "react-router-dom";

export default function SignupForm({ onSubmit, values, onChange, errors }) {
  // const navigate = useNavigate();
  return (
    <div className="flex-col justify-center items-center flex w-full">
      <form
        onSubmit={onSubmit}
        noValidate
        className="flex-col justify-center items-center flex w-full"
      >
        <div className="w-full mb-6">
          <h4 className="font-normal text-lg  uppercase tracking-wider">
            Name
          </h4>

          <div className="border-b-2 py-2 border-gray-300 flex items-center justify-start w-full">
            <input
              className="w-full focus:outline-none text-lg font-normal border-0"
              name="name"
              placeholder="Your Name"
              value={values.name}
              onChange={onChange}
              error={errors.name ? "true" : "false"}
              type="name"
            />
          </div>
          {errors.name && (
            <p className="text-red-800 md:text-xs">
              <b>&#33;</b> {errors.name}
            </p>
          )}
        </div>
        
        <div className="w-full mb-6">
          <h4 className="font-normal text-lg  uppercase tracking-wider">
            Email
          </h4>
          <div className="border-b-2 py-2 border-gray-300 flex items-center justify-start w-full">
            {/* <MdPersonOutline size={32} /> */}
            <input
              className="w-full focus:outline-none text-lg font-normal border-0"
              name="email"
              placeholder="Your Email"
              value={values.email}
              onChange={onChange}
              error={errors.email ? "true" : "false"}
              type="text"
            />
          </div>
          {errors.email && (
            <p className="text-red-800 md:text-xs">
              <b>&#33;</b> {errors.email}
            </p>
          )}
        </div>
        <div className="w-full mb-6">
          <h4 className="font-normal text-lg  uppercase tracking-wider">
            Password
          </h4>
          <div className="border-b-2 py-2 border-gray-300 flex items-center justify-start w-full">
            <input
              className="w-full px-0 py-0 focus:outline-none text-lg font-normal border-0"
              name="password"
              placeholder="Your Password"
              value={values.password}
              onChange={onChange}
              error={errors.password ? "true" : "false"}
              type="password"
            />
          </div>
        </div>
        <div className="w-full">
          <h4 className="font-normal text-lg uppercase tracking-wider">
            Confirm Password
          </h4>
          <div className="border-b-2 py-2 border-gray-300 flex items-center justify-start w-full">
            {/* <MdPersonOutline size={32} /> */}
            <input
              className="w-full px-0 py-0 focus:outline-none text-lg font-normal border-0"
              name="confirmPassword"
              placeholder="Confirm Password"
              value={values.confirmPassword}
              onChange={onChange}
              error={errors.confirmPassword ? "true" : "false"}
              type="password"
            />
          </div>
          {errors.confirmPassword && (
            <p className="text-amber-800 md:text-xs">
              <b>&#33;</b> {errors.confirmPassword}
            </p>
          )}
        </div>
        <button
          type="submit"
          className="mt-8 uppercase hover:shadow-lg text-md w-64 sm:w-48 flex items-center justify-center focus:ring text-white bg-amber-800 shadow-md border border-amber-800  py-2 px-6 rounded-xl focus:outline-none"
        >
          Sign Up
        </button>
        <button
          onClick={(e) => {
            e.preventDefault();
            // navigate("/login");
            window.location.href = `${window.location.origin}/login`;
          }}
          className="mt-4 hover:opacity-80 uppercase text-md bg-white text-amber-800  text-bold tracking-wide px-6 rounded-full focus:text-gray-500 focus:outline-none"
        >
          or Log In
        </button>
      </form>
    </div>
  );
}