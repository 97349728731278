import React from "react";

export default function Header({ title, description }) {
  return (
    <>
      <h2 className="text-5xl text-left">{title}</h2>
      <h2 className="text-2xl font-normal lg:font-light text-left mt-10">
        {description}
      </h2>
    </>
  );
}