import React from "react";
export default function HostAccount() {
  return (
    <div className="h-full flex flex-col min-h-screen w-full">
      <div className="h-full flex-1 flex mx-8 sm:mx-24 md:mx-32 lg:mx-48 mb-8">
        <div className="flex items-start justify-start flex-col w-full">
          HostAccount
        </div>
      </div>
    </div>
  );
}