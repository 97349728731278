import "./App.css";
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Welcome from "./pages/common/Welcome";
import HostAccount from "./pages/host/HostAccount";
import HostProfile from "./pages/host/HostProfile";
import GuestProfile from "./pages/guest/GuestProfile";
import GuestAuthRoute from "./util/GuestAuthRoute";
import { HostAuthProvider } from "./context/hostAuth";
import { GuestAuthProvider } from "./context/guestAuth";
import Signup from "./pages/common/Signup";
import Login from "./pages/common/Login";
import Logout from "./pages/common/Logout";
import Unsubscribe from "./pages/common/Unsubscribe";

function App() {
  return (
    <>
      <Router>
        <Routes>
          {/* <Route path="/" element={<Welcome />} /> */}
          <Route
            path="/logout"
            element={
              <GuestAuthRoute>
                <Logout />
              </GuestAuthRoute>
            }
          />
          <Route exact path="/unsubscribe/:subscriberId" element={<Unsubscribe />} />
          <Route path="/hostProfile" element={<HostProfile />} />
        </Routes>
      </Router>
      <GuestAuthProvider>
        <Router>
          <Routes>
            <Route path="/" element={<Welcome />} />
            <Route path="/guestProfile" element={<GuestProfile />} />
            <Route
              path="/login"
              element={
                <GuestAuthRoute>
                  <Login initialUserType={"Guest"} />
                </GuestAuthRoute>
              }
            />
            <Route
              path="/signup"
              element={
                <GuestAuthRoute>
                  <Signup initialUserType={"Guest"} />
                </GuestAuthRoute>
              }
            />
          </Routes>
        </Router>
      </GuestAuthProvider>
      <HostAuthProvider>
        <Router>
          <Routes>
            <Route path="/hostProfile" element={<HostProfile />} />
            <Route path="/hostAccount" element={<HostAccount />} />
          </Routes>
        </Router>
      </HostAuthProvider>
    </>
  );
}

export default App;
