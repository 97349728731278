import React, { useState } from "react";
import { useForm } from "../../util/hooks";
import { gql, useMutation } from "@apollo/client";
import { AiOutlineClose } from "react-icons/ai";
import profile from "../../images/profile.jpeg";
import ProfileForm from "./ProfileForm";
import { guestClient } from "../../GraphqlApolloClients";
import { GET_GUEST_BY_ID } from "../../pages/guest/GuestProfile";
// import { IoIosBriefcase, IoMdPerson, IoIosBulb } from "react-icons/io";

export default function ProfileContent({ guest }) {
  const [showConfirmation, setShowConfirmation] = useState(false);
  function closeConfirmation(e) {
    e.preventDefault();
    setShowConfirmation(false);
  }

  const [errors, setErrors] = useState({});
  const {
    values,
    onChange,
    onSubmit,
  } = useForm(addGuestProfileCallback, {
    guestId: guest.id,
    bedrooms: guest.bedrooms,
    bathrooms: guest.bathrooms,
    startDate: guest.startDate,
    duration: guest.duration,
    gender: guest.gender ?? "ANY",
    rentMin: guest.rentMin,
    rentMax: guest.rentMax,
    university: guest.university ?? "WATERLOO",
    roommates: guest.roommates,
  });
  const [addGuestProfile] = useMutation(ADD_GUEST_PROFILE, {
    refetchQueries: [
          {
            query: GET_GUEST_BY_ID,
            variables: { guestId: guest.id },
          },
    ],
    update(proxy, { data: { addGuestProfile: updatedGuest } }) {
      // console.log("success");
      setErrors({});
      values.guestId = updatedGuest.id;
      values.bedrooms = updatedGuest.bedrooms;
      values.bathrooms = updatedGuest.bathrooms;
      values.startDate = updatedGuest.startDate;
      values.duration = updatedGuest.duration;
      values.gender = updatedGuest.gender;
      values.rentMin = updatedGuest.rentMin;
      values.rentMax = updatedGuest.rentMax;
      values.university = updatedGuest.university;
      values.roommates = updatedGuest.roommates;
      // console.log("the new values are ");
      // console.log(values)
      setShowConfirmation(true);
    },
    onError(err) {
      console.log(err);
      setErrors(err.graphQLErrors[0].extensions.exception.errors);
    },
    variables: {
      ...values,
      startTime: values.startTime?.toISOString(), // Convert to ISO string
    },
    client: guestClient,
  });
  function addGuestProfileCallback() {
    addGuestProfile();
  }

  return (
    <div className="mt-8 flex w-full md:space-x-12 md:flex-row flex-col">
      <div className="flex flex-col space-y-4">
        <div
          className="flex px-32 md:px-64 py-32 items-center justify-center bg-no-repeat h-full bg-center bg-cover rounded-xl"
          style={{ backgroundImage: `url(${profile})` }}
        ></div>
      </div> 
      <div className="flex-col justify-center items-center flex w-full">
        {showConfirmation && (
          <div className="flex w-full justify-between p-4 rounded-lg border-2 border-amber-800 mt-8 md:mt-0 mb-0 md:mb-6">
            <h2 className="font-normal text-lg text-amber-800">
              Thanks for updating your profile! We'll keep you posted with relevant places soon.
            </h2>
            <button onClick={closeConfirmation}>
              <AiOutlineClose size={32} />
            </button>
          </div>
        )}
        <ProfileForm
            values={values}
            onChange={onChange}
            onSubmit={onSubmit}
            errors={errors}
        />
      </div>
    </div>
  );
}

const ADD_GUEST_PROFILE = gql`
mutation addGuestProfile(
    $guestId: String!
    $bedrooms: Int
    $bathrooms: Int
    $startDate: String
    $duration: Int
    $gender: Gender
    $rentMin: Float
    $rentMax: Float
    $university: University
    $roommates: Boolean
  ) {
    addGuestProfile(
      guestId: $guestId
      bedrooms: $bedrooms
      bathrooms: $bathrooms
      startDate: $startDate
      duration: $duration
      gender: $gender
      rentMin: $rentMin
      rentMax: $rentMax
      university: $university
      roommates: $roommates
    ) {
      id
      name
      password
      email
      createdAt
      bedrooms
      bathrooms
      startDate
      duration
      gender
      rentMin
      rentMax
      university
      roommates
    }
  }  
`;