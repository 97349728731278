import { gql, useMutation } from "@apollo/client";
import { hostClient } from "../../GraphqlApolloClients";
import React, {useState} from "react";
import { useForm } from "../../util/hooks";

export default function Subscribe({host}) {
  // const { data: { getSubscribers: subscribers } = {} } = useQuery(
  //   GET_SUBSCRIBERS,
  //   {
  //     client: hostClient,
  //     variables: { },
  //   }
  // );

  const [success, setSuccess] = useState(undefined);

  var email = "";

  const { values, onChange, onSubmit } = useForm(addSubscriberCallback, {
    email,
    host
  });

  const [addSubscriber] = useMutation(ADD_SUBSCRIBER, {
    // refetchQueries: [
    //   {
    //     query: GET_SUBSCRIBERS,
    //   },
    // ],
    update(cache, { data: { addSubscriber:newSubscriber } }) {
      if (newSubscriber) {
        setSuccess(true);
      } else {
        setSuccess(false);
      }
    },
    onError(err) {
      // console.log(err);
      setSuccess(false);
    },
    variables: values,
    client: hostClient, // TODO: Make an admin version. Actually use these.
  });

  function addSubscriberCallback() {
    addSubscriber();
  }

  return (
    <div className="w-full text-center flex-col text-white ">
        <form
          onSubmit={onSubmit}
          className="flex-col flex md:flex-row items-center justify-center mt-6"
        >
          <input name="email" value={values.email} placeholder="jane@gmail.com"
                  onChange={onChange} type="text" className={`input py-3 px-3 bg-white rounded-lg text-md focus:outline-none w-44 sm:w-64 border-2 text-gray-800 border-gray-100 ${success === true ? 'border-green-500' : success === false && 'border-red-600'}`} />

          <button
          type="submit"
                className="rounded-md tracking-wide uppercase text-xs bg-amber-800 md:ml-4 md:mt-0 mt-6 px-3.5 py-3  font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2  hover:bg-amber-900 focus-visible:outline-offset-2 focus-visible:outline-amber-800 w-28"
              >
                Subscribe
              </button>
        </form>
        {success === true ?
          (<p className={`pt-2 text-sm italic ${host && "text-gray-800"}`}>You're on our list!</p>) : (success === false && (<p className={`pt-2 text-sm italic ${host && "text-gray-800"}`}>You've already signed up with this email.</p>))
        }
      </div>
  );

}

const ADD_SUBSCRIBER = gql`
  mutation addSubscriber($email: String!, $host: Boolean) {
    addSubscriber(email: $email, host: $host) {
      id
      email
      host
      createdAt
    }
  }
`;

// const GET_SUBSCRIBERS = gql`
// query getSubscribers {
//   getSubscribers {
//     id
//     email
//   }
// }
// `;