import { split } from "@apollo/client";
import { ApolloClient, InMemoryCache } from "@apollo/client/core";
import { setContext } from "@apollo/client/link/context";
import { WebSocketLink } from "@apollo/client/link/ws";
import { getMainDefinition } from "@apollo/client/utilities";
import { createUploadLink } from "apollo-upload-client";

const backendURI =
  process.env.NODE_ENV === "production"
    ? "https://mvg-rentu-d114e0190027.herokuapp.com/"
    : "http://localhost:5000/";

const wsURI =
  process.env.NODE_ENV === "production"
    ? `wss://mvg-rentu-d114e0190027.herokuapp.com/subscriptions`
    : "ws://localhost:5000/subscriptions";

const httpLink = createUploadLink({
  uri: backendURI,
  // credentials: "include",
});

const wsLink = new WebSocketLink({
  uri: wsURI,
  options: {
    reconnect: true,
    lazy: true,
    // timeout: 30000,
    onError: (error) => {
      // error.message has to match what the server returns.
      if (
        error.message.contains("authorization") &&
        (localStorage.getItem("hostJwtToken") ||
          localStorage.getItem("guestJwtToken"))
      ) {
        // Reset the WS connection for it to carry the new JWT.
        wsLink.subscriptionClient.close(false, false);
      }
    },
    connectionParams: () => ({
      GuestAuth: `Bearer ${localStorage.getItem("guestJwtToken")}`,
      HostAuth: `Bearer ${localStorage.getItem("hostJwtToken")}`,
    }),
  },
});

const guestAuthLink = setContext(() => {
  const guestToken = localStorage.getItem("guestJwtToken");
  return {
    headers: {
      Authorization: guestToken ? `Bearer ${guestToken}` : "",
    },
  };
});
const guestLink = split(
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query);
    return kind === "OperationDefinition" && operation === "subscription";
  },
  wsLink,
  guestAuthLink.concat(httpLink)
);
export const guestClient = new ApolloClient({
  link: guestLink,
  uri: backendURI,
  cache: new InMemoryCache(),
});

const hostAuthLink = setContext(() => {
  const hostToken = localStorage.getItem("hostJwtToken");
  return {
    headers: {
      Authorization: hostToken ? `Bearer ${hostToken}` : "",
    },
  };
});

const hostLink = split(
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query);
    return kind === "OperationDefinition" && operation === "subscription";
  },
  wsLink,
  hostAuthLink.concat(httpLink)
);

export const hostClient = new ApolloClient({
  link: hostLink,
  uri: backendURI,
  cache: new InMemoryCache(),
});
