
   
import React from "react";
import title from "../../images/title.jpeg";
import Subscribe from "./Subscribe";

export default function Title() {
  return (
    <div className="flex flex-col items-center w-full justify-center">
        {/* <a href="#text">Go to Section 1</a> */}
       

      <div
      className="h-screen bg-center relative bg-cover w-full"
      style={{ backgroundImage: `url(${title})` }}
    >
      <div className="absolute inset-0 h-full w-full bg-opacity-70 bg-black" />
      <div className="absolute inset-0 flex flex-col w-full justify-center items-center px-6">
      <svg width="45.75" height="69.75" viewBox="0 0 63 94" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2 18L2 92" stroke="white" strokeWidth="2" strokeLinecap="round"/>
        <path d="M32.0566 41.2017L2 92.2017" stroke="white" strokeWidth="2" strokeLinecap="round"/>
        <path d="M19.8113 28.2017L2.00001 91.2017" stroke="white" strokeWidth="2" strokeLinecap="round"/>
        <path d="M42.0755 54.2017L2 92.2017" stroke="white" strokeWidth="2" strokeLinecap="round"/>
        <path d="M3 14.1487C22.3085 -13.7388 82.4409 4.68686 44.9271 51" stroke="white" strokeWidth="2" strokeLinecap="round"/>
        <path d="M21 25.306C25.3593 11.3962 49.3353 18.2935 33.8562 38" stroke="white" strokeWidth="2" strokeLinecap="round"/>
        <path d="M32.0566 67.2017L61 91.2017" stroke="white" strokeWidth="2" strokeLinecap="round"/>
        <path d="M45.4151 54.2017L61 91.2017" stroke="white" strokeWidth="2" strokeLinecap="round"/>
      </svg>
              
      <h1 className="pt-4 text-4xl font-bold tracking-tight sm:text-6xl text-white">
        Sublets and subleases for students
      </h1>
      <p className="mt-6 text-lg md:leading-8 text-white leading-12">
        Looking for a place to rent for the next 4-8 months? Skip the hassle and sign up for first access to verified, short-term rentals near you.
      </p>
      <Subscribe host={false} />
      </div>
    </div>
<div id="text"></div>
    </div>
  );
}
