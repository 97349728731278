import React, { useState, useContext } from "react";
import { useForm } from "../../util/hooks";
import { HostAuthContext } from "../../context/hostAuth";
import { GuestAuthContext } from "../../context/guestAuth";
import { gql, useMutation } from "@apollo/client";
import login from "../../images/login.webp";
import LoginForm from "./LoginForm";
// import {
//   MdOutlineCheckBoxOutlineBlank,
//   MdOutlineCheckBox,
// } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { guestClient } from "../../GraphqlApolloClients";

export default function LoginContent({ initialUserType }) {
  // const userTypes = ["Guest", "Host"];
  const navigate = useNavigate();

  // const [userType, setUserType] = useState(initialUserType);
  const hostContext = useContext(HostAuthContext);
  const guestContext = useContext(GuestAuthContext);

  // function toggleUserType(e, userTypeSelected) {
  //   e.preventDefault();
  //   setUserType(userTypeSelected);
  // }

  const [errorsGuest, setErrorsGuest] = useState({});
  const {
    values: valuesGuest,
    onChange: onChangeGuest,
    onSubmit: onSubmitGuest,
  } = useForm(loginGuestCallback, {
    email: "",
    password: "",
  });
  const [loginGuest] = useMutation(LOGIN_GUEST, {
    refetchQueries: [],
    update(proxy, { data: { loginGuest: guestData } }) {
      setErrorsGuest({});
      // console.log("logged in");
      // console.log(guestData);
      guestContext.logoutGuest();
      hostContext.logoutHost();
      guestContext.loginGuest(guestData);
      // console.log(guestContext);
      navigate('/guestProfile');
      // window.location.href = `${window.location.origin}/${userType.toLowerCase()}Profile`;
    },
    onError(err) {
      // console.log(err);
      setErrorsGuest(err.graphQLErrors[0].extensions.exception.errors || {});
    },
    variables: valuesGuest,
    client: guestClient,
  });
  function loginGuestCallback() {
    loginGuest();
  }

  // const [errorsHost, setErrorsHost] = useState({});
  // const {
  //   values: valuesHost,
  //   onChange: onChangeHost,
  //   onSubmit: onSubmitHost,
  // } = useForm(loginHostCallback, {
  //   email: "",
  //   password: "",
  // });
  // const [loginHost] = useMutation(LOGIN_HOST, {
  //   refetchQueries: [],
  //   update(proxy, { data: { loginHost: hostData } }) {
  //     // console.log("logging in...");
  //     hostContext.loginHost(hostData);
  //     navigate('/hostProfile');
  //   },
  //   onError(err) {
  //     // console.log(err);
  //     setErrorsHost(err.graphQLErrors[0].extensions.exception.errors || {});
  //   },
  //   variables: valuesHost,
  //   client: guestClient,
  // });
  // function loginHostCallback() {
  //   loginHost();
  // }

  return (
    <div className="mt-8 flex w-full md:space-x-12 md:flex-row flex-col">
      <div className="flex flex-col space-y-4">
        {/* <div className="flex w-full justify-between px-4 pb-4 border-b-2 border-amber-800">
          {userTypes.map((userTypeOption, index) => (
            <button
              key={index}
              onClick={(e) => {
                toggleUserType(e, userTypeOption);
              }}
              className="flex space-x-1 items-center justify-center hover:opacity-75 focus:outline-none"
            >
              <h4 className="font-light text-md">{userTypeOption}</h4>
              {userType === userTypeOption ? (
                <MdOutlineCheckBox size={20} color="#92400E" />
              ) : (
                <MdOutlineCheckBoxOutlineBlank size={20} color="#92400E" />
              )}
            </button>
          ))}
        </div> */}
        <div
          className="flex px-32 md:px-64 py-32 items-center justify-center bg-no-repeat h-full bg-center bg-cover rounded-xl"
          style={{ backgroundImage: `url(${login})` }}
        ></div>
      </div>
      {/* <LoginForm userType={userType} /> */}

      <div className="flex-col justify-center items-center flex w-full mt-8 md:mt-0">
      <LoginForm
                values={valuesGuest}
                onChange={onChangeGuest}
                onSubmit={onSubmitGuest}
                errors={errorsGuest}
              />
        {/* {userType ? (
          <>
            {userType === "Guest" && (
              <LoginForm
                values={valuesGuest}
                onChange={onChangeGuest}
                onSubmit={onSubmitGuest}
                errors={errorsGuest}
              />
            )}

            {userType === "Host" && (
              <LoginForm
                values={valuesHost}
                onChange={onChangeHost}
                onSubmit={onSubmitHost}
                errors={errorsHost}
              />
            )}
          </>
        ) : (
          <></>
        )} */}
      </div>
    </div>
  );
}

const LOGIN_GUEST = gql`
  mutation loginGuest($email: String!, $password: String!) {
    loginGuest(email: $email, password: $password) {
      id
      email
      name
      createdAt
      token
    }
  }
`;

// const LOGIN_HOST = gql`
//   mutation loginHost($email: String!, $password: String!) {
//     loginHost(email: $email, password: $password) {
//       id
//       email
//       name
//       createdAt
//       token
//     }
//   }
// `;