import React from "react";
import Title from "../../components/common/Title";
import Steps from "../../components/common/Steps";
import Footer from "../../components/common/Footer";
import MoreInfo from "../../components/common/MoreInfo";
import NavBar from "../../components/common/NavBar";
export default function Welcome() {
  return (
    <div className="h-full flex flex-col min-h-screen w-full">
      <NavBar />
      <div className="h-full flex-1 flex">
        <div className="flex items-center justify-center flex-col w-full">
          <div className="text-center w-full ">
            <Title />
          </div>
          <div className="px-16 md:px-32">
            <Steps />
          </div>
          <div className="px-16 py-32 md:px-32 w-full">
            <MoreInfo />
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
}
