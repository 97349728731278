import React, { useContext, useState } from "react";
import { useForm } from "../../util/hooks";
import { gql, useMutation } from "@apollo/client";
import { AiOutlineClose } from "react-icons/ai";
import signup from "../../images/signup.jpeg";
import SignupForm from "./SignupForm";
import { guestClient } from "../../GraphqlApolloClients";
import { HostAuthContext } from "../../context/hostAuth";
import { GuestAuthContext } from "../../context/guestAuth";
import { useNavigate } from "react-router-dom";
// import { IoIosBriefcase, IoMdPerson, IoIosBulb } from "react-icons/io";

export default function SignupContent({ initialUserType }) {
  const navigate = useNavigate();

  // const userTypes = ["Guest", "Host"];
  // const [userType, setUserType] = useState(initialUserType);

  const hostContext = useContext(HostAuthContext);
  const guestContext = useContext(GuestAuthContext);

  // function toggleUserType(e, userTypeSelected) {
  //   e.preventDefault();
  //   setUserType(userTypeSelected);
  // }

  const [showConfirmation, setShowConfirmation] = useState(false);
  function closeConfirmation(e) {
    e.preventDefault();
    setShowConfirmation(false);
  }

  const [errorsGuest, setErrorsGuest] = useState({});
  const {
    values: valuesGuest,
    onChange: onChangeGuest,
    onSubmit: onSubmitGuest,
  } = useForm(signupGuestCallback, {
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
  });
  const [signupGuest] = useMutation(SIGNUP_GUEST, {
    refetchQueries: [],
    update(proxy, { data: { signupGuest: guestData } }) {
      // setErrorsGuest({});
      // valuesGuest.name = "";
      // valuesGuest.email = "";
      // valuesGuest.password = "";
      // valuesGuest.confirmPassword = "";
      // setShowConfirmation(true);

      guestContext.logoutGuest();
      hostContext.logoutHost();
      guestContext.loginGuest(guestData);

      navigate('/guestProfile'); 
    },
    onError(err) {
      console.log(err);
      setErrorsGuest(err.graphQLErrors[0].extensions.exception.errors);
    },
    variables: valuesGuest,
    client: guestClient,
  });
  function signupGuestCallback() {
    signupGuest();
  }

  // const [errorsHost, setErrorsHost] = useState({});
  // const {
  //   values: valuesHost,
  //   onChange: onChangeHost,
  //   onSubmit: onSubmitHost,
  // } = useForm(signupHostCallback, {
  //   name: "",
  //   email: "",
  //   password: "",
  //   confirmPassword: "",
  // });
  // const [signupHost] = useMutation(SIGNUP_HOST, {
  //   refetchQueries: [],
  //   update(proxy, { data: { signupHost: categoryData } }) {
  //     setErrorsHost({});
  //     valuesHost.name = "";
  //     valuesHost.email = "";
  //     valuesHost.password = "";
  //     valuesHost.confirmPassword = "";
  //     setShowConfirmation(true);
  //   },
  //   onError(err) {
  //     // console.log(err);
  //     setErrorsHost(err.graphQLErrors[0].extensions.exception.errors);
  //   },
  //   variables: valuesHost,
  //   client: hostClient,
  // });
  // function signupHostCallback() {
  //   signupHost();
  // }

  return (
    <div className="mt-8 flex w-full md:space-x-12 md:flex-row flex-col">
      <div className="flex flex-col space-y-4">
        {/* <div className="flex w-full justify-between px-4 pb-4 border-b-2 border-amber-800">
          {userTypes.map((userTypeOption, index) => (
            <button
              key={index}
              onClick={(e) => {
                toggleUserType(e, userTypeOption);
              }}
              className="flex space-x-1 items-center justify-center hover:opacity-75 focus:outline-none"
            >
              <h4 className="font-light text-md">{userTypeOption}</h4>
              {userType === userTypeOption ? (
                <MdOutlineCheckBox size={20} color="#92400E" />
              ) : (
                <MdOutlineCheckBoxOutlineBlank size={20} color="#92400E" />
              )}
            </button>
          ))}
        </div> */}
        <div
          className="flex px-32 md:px-64 py-32 items-center justify-center bg-no-repeat h-full bg-center bg-cover rounded-xl"
          style={{ backgroundImage: `url(${signup})` }}
        ></div>
      </div>
      {/* <SignupForm userType={userType} /> */}

      <div className="flex-col justify-center items-center flex w-full mt-8 md:mt-0">
        {showConfirmation && (
          <div className="flex w-full justify-between mb-6 p-4 rounded-lg border-2 border-amber-800">
            <h2 className="font-normal text-lg text-amber-800">
              You have signed up!
            </h2>
            <button onClick={closeConfirmation}>
              <AiOutlineClose size={32} />
            </button>
          </div>
        )}
        <SignupForm
                values={valuesGuest}
                onChange={onChangeGuest}
                onSubmit={onSubmitGuest}
                errors={errorsGuest}
              />
        {/* {userType ? (
          <>
            {userType === "Guest" && (
              <SignupForm
                values={valuesGuest}
                onChange={onChangeGuest}
                onSubmit={onSubmitGuest}
                errors={errorsGuest}
              />
            )}

            {userType === "Host" && (
              <SignupForm
                values={valuesHost}
                onChange={onChangeHost}
                onSubmit={onSubmitHost}
                errors={errorsHost}
              />
            )}
          </>
        ) : (
          <></>
        )} */}
      </div>
    </div>
  );
}

// const SIGNUP_HOST = gql`
//   mutation signupHost(
//     $email: String!
//     $password: String!
//     $confirmPassword: String!
//     $name: String!
//   ) {
//     signupHost(
//       email: $email
//       password: $password
//       confirmPassword: $confirmPassword
//       name: $name
//     ) {
//       id
//       email
//       name
//       createdAt
//       token
//     }
//   }
// `;

const SIGNUP_GUEST = gql`
  mutation signupGuest(
    $email: String!
    $password: String!
    $confirmPassword: String!
    $name: String!
  ) {
    signupGuest(
      email: $email
      password: $password
      confirmPassword: $confirmPassword
      name: $name
    ) {
      id
      email
      name
      createdAt
      token
    }
  }
`;