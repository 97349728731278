
   
import React from "react";
import Subscribe from "./Subscribe";

export default function MoreInfo() {
  return (
    <div className="flex flex-col py-16 w-full justify-start border-2 rounded-lg border-amber-800 px-6">
      <h3 className="font-semibold text-xl">Want to rent something out?</h3>
      <p className="text-lg py-1 leading-28">Looking for a roommate to share your place? RentU validates the background of all renters on the platform and streamlines the process to have your space occupied ASAP. Sign up for first access now.</p>
      <Subscribe host={true} />
    </div>
  );
}
