import React from "react";
import Header from "../../components/common/Header";
import LoginContent from "../../components/common/LoginContent";
// import { HostAuthContext } from "../../context/hostAuth";
// import { GuestAuthContext } from "../../context/guestAuth";
import NavBar from "../../components/common/NavBar";
import Footer from "../../components/common/Footer";

export default function Login({ initialUserType }) {
    // const hostContext = useContext(HostAuthContext);
    // const guestContext = useContext(GuestAuthContext);
    // // TODO: Make this go to the profile directly if needed -- it's not in the correct provider.
    // if (hostContext.host) {
    //     console.log("host");
    // } else if (guestContext.guest) {
    //     console.log("guest");
    // }

    return (
        <div className="h-full flex flex-col min-h-screen w-full">
            <NavBar />
            <div className="h-full flex-1 flex">
                <div className="flex items-start justify-center flex-col w-full">
                    <div className="px-16 md:px-32 w-full py-16 h-full">
                        <Header
                            title={"Log In"}
                            description={"Welcome back! Log into your account below."}
                        />
                        <LoginContent initialUserType={initialUserType} />
                    </div>
                    <Footer />
                </div>
            </div>
        </div>
    );
}