import jwtDecode from "jwt-decode";
import React, { createContext, useReducer } from "react";

const initialState = {
  user: null,
};

if (localStorage.getItem("jwtToken")) {
  const decodedToken = jwtDecode(localStorage.getItem("jwtToken"));

  if (decodedToken.exp * 1000 < Date.now()) {
    localStorage.removeItem("jwtToken");
  } else {
    initialState.guest = decodedToken;
  }
}

const GuestAuthContext = createContext({
  guest: null,
  loginGuest: (guestData) => { },
  logoutGuest: () => { },
});

function guestAuthReducer(state, action) {
  switch (action.type) {
    case "LOGIN_GUEST":
      return {
        ...state,
        guest: action.payload,
      };
    case "LOGOUT_GUEST":
      return {
        ...state,
        guest: null,
      };
    default:
      return state;
  }
}

function GuestAuthProvider(props) {
  const [state, dispatch] = useReducer(guestAuthReducer, initialState);

  function loginGuest(guestData) {
    // console.log("in now");
    // console.log(guestData);
    localStorage.setItem("jwtToken", guestData.token);
    dispatch({
      type: "LOGIN_GUEST",
      payload: guestData,
    });
    // console.log("made it here");
  }

  function logoutGuest() {
    // console.log("inn now");
    localStorage.removeItem("jwtToken");
    dispatch({ type: "LOGOUT_GUEST" });
  }

  return (
    <GuestAuthContext.Provider
      value={{ guest: state.guest, loginGuest, logoutGuest }}
      {...props}
    />
  );
}

export { GuestAuthContext, GuestAuthProvider };