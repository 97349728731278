import React, {useContext} from "react";
import { useNavigate, useLocation } from "react-router-dom";
import logo from "../../images/logoWhite.svg";
import NavDropdown from "./NavDropdown";
import { GuestAuthContext } from "../../context/guestAuth";
import { HostAuthContext } from "../../context/hostAuth";
import { guestClient, hostClient } from "../../GraphqlApolloClients";

export default function NavBar({ props }) {
  const navigate = useNavigate();
  const location = useLocation();
  const hostContext = useContext(HostAuthContext);
  const guestContext = useContext(GuestAuthContext);

//   const pageLinksAndTitles = [
//     { title: "Home", link: "/" },
//     { title: "Profile", link: "/guestProfile" },
//     { title: `${guestContext.guest ? 'Logout' : 'Log In'}`, link: `${guestContext.guest ? '/logout' : '/login'}` },
//   ];

  var pageLinksAndTitles;
  if (guestContext.guest) {
     pageLinksAndTitles = [
        { title: "Home", link: "/" },
        { title: "Profile", link: "/guestProfile" },
        { title: 'Logout', link: '/logout' },
      ];
  } else {
    pageLinksAndTitles = [
        { title: "Home", link: "/" },
        { title: 'Login', link: '/login' },
      ];
  }

  return (
    <div className="bg-black shadow-lg flex items-center justify-center w-full z-10 text-center">
      <nav className="py-4 mx-8 sm:mx-28 md:mx-32 lg:mx-36 flex items-center justify-center  w-full text-lg">
        <div className="w-full flex items-center justify-center font-light text-md text-white ">
          <div className="items-center flex flex-1 h-full md:h-8 justify-start md:hidden">
            <NavDropdown
              props={props}
              pageLinksAndTitles={pageLinksAndTitles}
            />
          </div>
          <button
            onClick={(e) => {
              e.preventDefault();
              navigate("/");
            }}
            className="hover:opacity-75 hidden md:flex font-light items-center justify-center  focus:outline-none focus:ring"
          >
            <img src={logo} className="object-cover w-5 " alt="RentU Logo" />
          </button>
          <div className="flex-1 flex items-center justify-end">
            {pageLinksAndTitles.map((pageInfo) => (
              <button
                key={pageInfo.title}
                onClick={(e) => {
                    e.preventDefault();

                if(pageInfo.link==='/logout') {
                    guestContext.logoutGuest();
                    hostContext.logoutHost();
                
                    guestClient.cache.reset();
                    hostClient.cache.reset();     
                    window.location.href = `${window.location.origin}/`;           
                } else {
                    // navigate(pageInfo.link);
                    window.location.href = `${window.location.origin}${pageInfo.link}`;
                }
                 
                }}
                className={`hidden md:block hover:opacity-75 font-light focus:outline-none ${
                  pageLinksAndTitles[pageLinksAndTitles.length-1].link !== pageInfo.link&& `mr-4`
                } ${pageInfo.link === location.pathname && 'font-semibold'}`}
              > 
                {pageInfo.title}
              </button>
              // TODO(Functionality) make the links to the different parts of the page work too
            ))}
          </div>
        </div>
      </nav>
    </div>
  );
}