import React, { useState } from "react";

export default function ProfileForm({ onSubmit, values, onChange, errors }) {
  const [university, setUniversity] = useState(values.university);
  const [gender, setGender] = useState(values.gender);
  const [roommates, setRoommates] = useState(values.roommates === true ? "Yes": "No");

  const universityOptions = ["WATERLOO", "LAURIER"];
  const genderOptions = ["FEMALE", "MALE", "ANY"];
  const truthOptions = ["Yes", "No"];

  const handleDropdownChange = (event) => {
    const val = event.target.value;

    // console.log(val);

    if (universityOptions.includes(val)) {
      // console.log("university");
      setUniversity(val);
      values.university = val;
    } else if (genderOptions.includes(val)) {
      // console.log("gender");
      setGender(val);
      values.gender = val;
    } else if (truthOptions.includes(val)) {
      // console.log("truth");
      setRoommates(val);
      values.roommates = val === "Yes"? true : false;
    }

    // console.log("new values:")
    // console.log(values);

    onChange(event);
  };

  return (
    <div className="flex-col justify-center items-center flex w-full mt-8 md:mt-0">
      <form
        onSubmit={onSubmit}
        noValidate
        className="flex-col justify-center items-center flex w-full"
      >
        <div className="w-full mb-6">
          <h4 className="font-normal text-lg  uppercase tracking-wider">
            Bedrooms
          </h4>

          <div className="border-b-2 py-2 border-gray-300 flex items-center justify-start w-full">
            <input
              className="w-full focus:outline-none text-lg font-normal border-0"
              name="bedrooms"
              placeholder={0}
              value={values.bedrooms}
              onChange={onChange}
              error={errors.name ? "true" : "false"}
              min={0}
              max={10}
              type="number"
            />
          </div>
          {errors.bedrooms && (
            <p className="text-red-800 md:text-xs">
              <b>&#33;</b> {errors.bedrooms}
            </p>
          )}
        </div>
        
        <div className="w-full mb-6">
          <h4 className="font-normal text-lg  uppercase tracking-wider">
            Bathrooms
          </h4>

          <div className="border-b-2 py-2 border-gray-300 flex items-center justify-start w-full">
            <input
              className="w-full focus:outline-none text-lg font-normal border-0"
              name="bathrooms"
              placeholder={0}
              value={values.bathrooms}
              onChange={onChange}
              error={errors.name ? "true" : "false"}
              min={0}
              max={10}
              type="number"
            />
          </div>
          {errors.bathrooms && (
            <p className="text-red-800 md:text-xs">
              <b>&#33;</b> {errors.bathrooms}
            </p>
          )}
        </div>

        <div className="w-full mb-6">
          <h4 className="font-normal text-lg  uppercase tracking-wider">
            Start Date
          </h4>

          <div className="border-b-2 py-2 border-gray-300 flex items-center justify-start w-full">
            <input
              className="w-full focus:outline-none text-lg font-normal border-0"
              name="startDate"
              placeholder="YYYY-MM-DD"
              value={values.startDate}
              onChange={onChange}
              error={errors.name ? "true" : "false"}
              type="text"
            />          </div>
          {errors.startDate && (
            <p className="text-red-800 md:text-xs">
              <b>&#33;</b> {errors.startDate}
            </p>
          )}
        </div>

        <div className="w-full mb-6">
          <h4 className="font-normal text-lg  uppercase tracking-wider">
            Duration
          </h4>
          <p className="text-gray-500 text-sm leading-tight">The number of months you're targeting.</p>
          <div className="border-b-2 py-2 border-gray-300 flex items-center justify-start w-full">
            <input
              className="w-full focus:outline-none text-lg font-normal border-0"
              name="duration"
              placeholder={4}
              min={1}
              max={24}
              value={values.duration}
              onChange={onChange}
              error={errors.name ? "true" : "false"}
              type="number"
            />
          </div>
          {errors.duration && (
            <p className="text-red-800 md:text-xs">
              <b>&#33;</b> {errors.duration}
            </p>
          )}
        </div>

        <div className="w-full mb-6">
          <h4 className="font-normal text-lg  uppercase tracking-wider">
            Minimum Rent
          </h4>
          <p className="text-gray-500 text-sm leading-tight">On a monthly basis.</p>
          <div className="border-b-2 py-2 border-gray-300 flex items-center justify-start w-full">
            <input
              className="w-full focus:outline-none text-lg font-normal border-0"
              name="rentMin"
              placeholder={0}
              min={100}
              max={5000}
              step={10}
              value={values.rentMin}
              onChange={onChange}
              error={errors.name ? "true" : "false"}
              type="number"
            />
          </div>
          {errors.rentMin && (
            <p className="text-red-800 md:text-xs">
              <b>&#33;</b> {errors.rentMin}
            </p>
          )}
        </div>

        <div className="w-full mb-6">
          <h4 className="font-normal text-lg  uppercase tracking-wider">
            Maximum Rent
          </h4>
          <p className="text-gray-500 text-sm leading-tight">On a monthly basis.</p>
          <div className="border-b-2 py-2 border-gray-300 flex items-center justify-start w-full">
            <input
              className="w-full focus:outline-none text-lg font-normal border-0"
              name="rentMax"
              placeholder={0}
              min={100}
              max={5000}
              step={10}
              value={values.rentMax}
              onChange={onChange}
              error={errors.name ? "true" : "false"}
              type="number"
            />
          </div>
          {errors.rentMax && (
            <p className="text-red-800 md:text-xs">
              <b>&#33;</b> {errors.rentMax}
            </p>
          )}
        </div>

        <div className="w-full mb-6">
          <h4 className="font-normal text-lg  uppercase tracking-wider">
           University
          </h4>

          <div className="border-b-2 py-2 border-gray-300 flex items-center justify-start w-full">
            <select className="focus:outline-none" value={university} onChange={handleDropdownChange}>
              {universityOptions.map((option, index) => (
                <option key={index} value={option}>{option}</option>
              ))}
            </select>
          </div>
          {errors.university && (
            <p className="text-red-800 md:text-xs">
              <b>&#33;</b> {errors.university}
            </p>
          )}
        </div>

        <div className="w-full mb-6">
          <h4 className="font-normal text-lg  uppercase tracking-wider">
           Roommates
          </h4>
          <p className="text-gray-500 text-sm leading-tight">Whether or not you're looking for roommates.</p>
          <div className="border-b-2 py-2 border-gray-300 flex items-center justify-start w-full">
            <select className="focus:outline-none" value={roommates} onChange={handleDropdownChange}>
              {truthOptions.map((option, index) => (
                <option key={index} value={option}>{option}</option>
              ))}
            </select>
          </div>
          {errors.roommates && (
            <p className="text-red-800 md:text-xs">
              <b>&#33;</b> {errors.roommates}
            </p>
          )}
        </div>
        {values.roommates === true && (<div className="w-full mb-6">
          <h4 className="font-normal text-lg  uppercase tracking-wider">
            Gender
          </h4>
<p className="text-gray-500 text-sm leading-tight">Gender preference for your roommates.</p>
          <div className="border-b-2 py-2 border-gray-300 flex items-center justify-start w-full">
            <select className="focus:outline-none" value={gender} onChange={handleDropdownChange}>
              {genderOptions.map((option, index) => (
                <option key={index} value={option}>{option}</option>
              ))}
            </select>
          </div>
          {errors.gender && (
            <p className="text-red-800 md:text-xs">
              <b>&#33;</b> {errors.gender}
            </p>
          )}
        </div>)}
        {/* TODO: Make this disabled until they change something. */}
        <button
          type="submit"
          className="mt-8 uppercase hover:shadow-lg text-md w-64 sm:w-48 flex items-center justify-center focus:ring text-white bg-amber-800 shadow-md border border-amber-800  py-2 px-6 rounded-xl focus:outline-none"
        >
          Submit
        </button>
       
      </form>
    </div>
  );
}