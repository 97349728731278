import React, { useEffect, useState } from "react";
import Footer from "../../components/common/Footer";
import { useParams } from "react-router-dom";
import logo from "../../images/logoBlack.svg";
import { guestClient } from "../../GraphqlApolloClients";
import { gql, useMutation } from "@apollo/client";

const Unsubscribe = () => {
  // const subscriberId = props.match.params.subscriberId;
  const { subscriberId } = useParams();

  const [desiredUnsubscribed, setDesiredUnsubscribed] = useState(true);
  const [newUnsubscribed, setNewUnsubscribed] = useState(false);

  // const [errors, setErrors] = useState({});

  const [toggleSubscriber] = useMutation(TOGGLE_SUBSCRIBER, {
    update(cache, { data: { toggleSubscriber: result } }) {
      // setErrors({});
      setNewUnsubscribed(result);
    },
    onError(err) {
      // console.log(err);
      // setErrors(err.graphQLErrors[0].extensions.exception.errors || {});
    },
    variables: {
      subscriberId,
      status: desiredUnsubscribed
    },
    client: guestClient,
  });

  useEffect(() => {
    // Call the mutation when the component mounts
    toggleSubscriber();
  }, [toggleSubscriber, desiredUnsubscribed, setDesiredUnsubscribed]); // The empty array ensures this effect runs only once on mount

  return (
    <div className="h-full flex flex-col min-h-screen w-full">
      <div className="h-full flex-1 flex">
        <div className="flex items-center justify-center flex-col w-full">
          <div className="h-full flex flex-col text-center  px-16 py-32 md:px-32 w-full justify-center items-center">
            <img src={logo} className="object-cover w-16 mx-auto pb-8" alt="RentU Logo" />
            {newUnsubscribed === "true" && (<h1 className="text-3xl pb-8">You've unsubscribed. Sorry to see you go.</h1>
            )}
            <button onClick={(e) => {
              e.preventDefault();
              setDesiredUnsubscribed(false);
            }} className="text-white rounded-lg px-6 py-3 tracking-wider hover:opacity-80 text-lg uppercase bg-amber-800">Resubscribe</button>
            {newUnsubscribed === "false" && (<p className="text-green-800 pt-6 italic text-2xl">You're back on our list!</p>
            )}
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default Unsubscribe;

const TOGGLE_SUBSCRIBER = gql`
  mutation toggleSubscriber($subscriberId: String!, $status: Boolean!) {
    toggleSubscriber(subscriberId: $subscriberId, status: $status)
  }
`;
